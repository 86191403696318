// ContentSection.component.js
import React from "react";
import styled from "@emotion/styled";
import { BulletTitle, DashedContainer, Paragraph, Subtitle } from "./styles";

interface ParentProps {
  title: string;
  img: string;
  alt: string;
  content: string[];
  bulletsDesc?: string[];
  floatDirection?: "left" | "right";
}

const ContentSection = ({
  title,
  img,
  alt,
  content,
  bulletsDesc,
  floatDirection,
}: ParentProps) => {
  return (
    <Wrapper>
      <Subtitle>{title}</Subtitle>
      <StyledImage
        src={img}
        alt={alt}
        title={alt}
        width="450px"
        height="auto"
        loading="eager"
        floatDirection={floatDirection}
      />
      {content.map((el, index) => (
        <React.Fragment key={index}>
          <Paragraph>{el}</Paragraph>
          <br />
        </React.Fragment>
      ))}
      {bulletsDesc &&
        bulletsDesc.map((el, index) => (
          <React.Fragment key={index}>
            {bulletsDesc && index !== bulletsDesc.length - 1 ? (
              <DashedContainer>
                <BulletTitle></BulletTitle>
                <Paragraph>{el}</Paragraph>
              </DashedContainer>
            ) : (
              <div style={{ paddingLeft: "44px", marginTop: "-35px" }}>
                <BulletTitle></BulletTitle>
                <Paragraph>{el}</Paragraph>
              </div>
            )}
          </React.Fragment>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  clear: both;
`;

const StyledImage = styled.img<{ floatDirection?: "left" | "right" }>`
  float: ${(props) => props.floatDirection || "none"};
  /* Apply margin-right when the floatDirection is "left" */
  ${(props) =>
    props.floatDirection === "left" &&
    `
    margin-right: 30px;
    @media screen and (max-width: 750px) {
      margin-right: 0;
      margin-left: auto;
      width: 100%;
      float: none;
    }
  `}
  /* Apply margin-left when the floatDirection is "right" */
  ${(props) =>
    props.floatDirection === "right" &&
    `
    margin-left: 30px;
    @media screen and (max-width: 750px) {
      margin-left: 0;
      margin-right: auto;
      width: 100%;
      float: none;
    }
  `}
`;

export default ContentSection;
